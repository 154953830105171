import { db } from "../firebase/firebase";
import { collection } from "firebase/firestore";
import { createDocV2 } from "../hooks/fetchFirebase";

const foodReviewRequestSubmit = async (submitObj) => {
  // place holder for future clena up
  const dbRef = collection(db, "food_review_request");
  const res = await createDocV2(dbRef, submitObj);
  return res;
};
export { foodReviewRequestSubmit };
